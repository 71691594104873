import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // Postavi siteKey u .env fajlu

const Footer = () => {
  // Initialize state variables
  const [tooltipContent, setTooltipContent] = useState(null);
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [errors, setErrors] = useState({});
  const [isSent, setIsSent] = useState(false);

  const showTooltip = (type) => {
    switch (type) {
      case 'terms':
        setTooltipContent(termsOfUseContent);
        break;
      case 'privacy':
        setTooltipContent(privacyContent);
        break;
      case 'about':
        setTooltipContent(aboutContent);
        break;
      case 'contact':
        setTooltipContent('contact');
        break;
      default:
        setTooltipContent(null);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = 'Name is required.';
    if (!email.trim()) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address.';
    }
    if (!message.trim()) errors.message = 'Message is required.';
    if (!captchaToken) errors.captcha = 'Please complete the reCAPTCHA.';
    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validacija forme
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Slanje zahteva backend-u
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, company, email, phone, message, captchaToken }),
      });

      if (response.ok) {
        //alert('Message sent successfully!');
		setIsSent(true);
		setTimeout(() => setIsSent(false), 5000);
        setName('');
        setCompany('');
        setEmail('');
        setPhone('');
        setMessage('');
        setCaptchaToken('');
        setErrors({});
      } else {
        const errorData = await response.json();
        alert(`Failed to send the message: ${errorData.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="relative">
      <div className="flex px-2 md:flex mt-6 justify-center items-center md:justify-center md:items-center">
        <div className="flex flex-col text-sm bg-lightBackground dark:bg-darkBackground p-2 px-2 md:w-[870px] md:h-max justify-center items-center md:justify-center md:items-center text-gray-700 dark:text-gray-200">
          <div className="space-x-4">
            <button className="hover:underline" onClick={() => showTooltip('terms')}>Terms of Use</button>
            <button className="hover:underline" onClick={() => showTooltip('privacy')}>Privacy</button>
            <button className="hover:underline" onClick={() => showTooltip('about')}>About W2U</button>
            <button className="hover:underline" onClick={() => showTooltip('contact')}>Contact Us</button>
          </div>
        </div>
      </div>

	  {tooltipContent && (
	    <div className="fixed inset-0 flex items-center justify-center z-50">
	      {/* Overlay background */}
	      <div className="absolute inset-0 bg-black opacity-50" onClick={() => setTooltipContent(null)}></div>

	      {/* Tooltip box */}
	      <div className="relative bg-white/90 dark:bg-darkBackground/90 text-gray-700 dark:text-gray-200 p-4 shadow-lg rounded-lg w-[90%] max-w-[400px] md:max-w-[600px] h-[400px] md:h-[530px]">
	        {/* Scrollable inner content */}
	        <div className="h-full overflow-y-auto">
	          {tooltipContent === 'contact' ? (
	            <div>
	              <h4 className="font-semibold text-lg mb-6">Contact Us</h4>
	              <p className="text-sm mb-4">Fill out the form below to reach out to our support team.</p>
	              <form onSubmit={handleFormSubmit} className="space-y-4">
	                {/* Input fields */}
	                <div className="flex justify-center">
	                  <input
	                    type="text"
	                    value={name}
	                    onChange={(e) => setName(e.target.value)}
	                    placeholder="Name"
	                    className={`w-4/5 p-2 border ${errors.name ? 'border-red-500' : 'border-gray-300 dark:border-gray-500'} rounded-md bg-cardLightBg/90 dark:bg-cardDarkBg/90 text-gray-700 dark:text-gray-200`}
	                    required
	                  />
	                </div>
	                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          
                <div className="flex justify-center">
                  <input
                    type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="Company"
                    className="w-4/5 p-2 border border-gray-300 dark:border-gray-500 rounded-md bg-cardLightBg/90 dark:bg-cardDarkBg/90 text-gray-700 dark:text-gray-200"
                  />
                </div>

                <div className="flex justify-center">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className={`w-4/5 p-2 border border-gray-300 dark:border-gray-500 rounded-md bg-cardLightBg/90 dark:bg-cardDarkBg/90 text-gray-700 dark:text-gray-200 ${errors.email ? 'border-red-500' : ''}`}
                    required
                  />
                </div>
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          
                <div className="flex justify-center">
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone"
                    className="w-4/5 p-2 border border-gray-300 dark:border-gray-500 rounded-md bg-cardLightBg/90 dark:bg-cardDarkBg/90 text-gray-700 dark:text-gray-200"
                  />
                </div>

                <div className="flex justify-center">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message"
                    className={`w-4/5 p-2 border border-gray-300 dark:border-gray-500 rounded-md bg-cardLightBg/90 dark:bg-cardDarkBg/90 text-gray-700 dark:text-gray-200 ${errors.message ? 'border-red-500' : ''}`}
                    rows="4"
                    required
                  ></textarea>
                </div>
                {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
	                {/* ReCAPTCHA v2 */}
	                <div className="flex justify-center mb-4">
	                  <ReCAPTCHA
	                    sitekey={siteKey}
	                    onChange={(token) => setCaptchaToken(token)}
	                  />
	                </div>
	                {errors.captcha && <p className="text-red-500 text-sm">{errors.captcha}</p>}
	                
	                <div className="flex justify-center">
					  <button
                        type="submit"
                        className={`w-2/5 p-2 mb-4 rounded-md ${
                          isSent ? 'bg-green-500' : 'bg-blue-500'
                        } text-white`}
                      >
                        {isSent ? 'Message Sent' : 'Send'}
                      </button>
	                </div>
	              </form>
	            </div>
	          ) : (
	            tooltipContent
	          )}
	        </div>
	      </div>
	    </div>
	  )}
    </div>
  );
};
// Definiši sadržaj tooltip-a
const termsOfUseContent = (
  <div className="popup_main_content">
    <h4 className="w2u_modal_heading font-semibold text-lg mb-6">Terms of use</h4>
    <p className="terms_attention">
      ATTENTION: PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEBSITE. USING THIS WEBSITE INDICATES THAT YOU ACCEPT THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS ("TERMS"), DO NOT USE THIS WEBSITE.
    </p>

    <p className="label_terms mt-4">Use of site</p>
    <p className="text_terms">
      Weather2Umbrella Company ("W2U") authorizes you to view and download the materials at this website ("Site") only for your personal, non-commercial use, provided that you retain all copyright and other proprietary notices contained in the original materials on any copies of the materials. You may not modify the materials at this Site in any way or reproduce or publicly display, perform, or distribute or otherwise use them for any public or commercial purpose. Any use of these materials on any other website or networked computer environment for any purpose is prohibited. The materials at this Site are copyrighted and any unauthorized use of any materials at this Site may violate copyright, trademark, and other laws. If you breach any of these Terms, your authorization to use this Site automatically terminates and you must immediately destroy any downloaded or printed materials.
    </p>

    <p className="label_terms">User submissions</p>
    <p className="text_terms">
      W2U does not want to receive confidential or proprietary information from you through this Site. Any material, information or other communication ("Communications") you transmit or post to this Site will be considered non-confidential and non-proprietary. W2U will have no obligations with respect to the Communications. W2U and its designees will be free to copy, disclose, distribute, incorporate and otherwise use the Communications and all data, images, sounds, text, and other things embodied therein for any and all commercial or non-commercial purposes. Personally identifiable information that you submit to W2U for the purpose of receiving products or services will be handled in accordance with the W2U online privacy statement. You are prohibited from posting or transmitting to or from this Site any unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material that would violate any law.
    </p>

    <p className="label_terms">Links to third-party Websites</p>
    <p className="text_terms">
      Links on this Site to third-party websites are provided solely as a convenience to you. If you use these links, you will leave this Site. W2U has not reviewed all of these third-party sites and does not control and is not responsible for any of these sites or their content. Thus, W2U does not endorse or make any representations about them, or any information, software or other products or materials found there, or any results that may be obtained from using them. If you decide to access any of the third-party websites linked to this Site, you do this entirely at your own risk.
    </p>
  </div>
);


const privacyContent = (
  <div className="popup_main_content">
    <h4 className="w2u_modal_heading font-semibold text-lg mb-6">Privacy Policy</h4>
    <p className="w2u_label">
      Weather2Umbrella Ltd <br />
      <a className="w2u_link" href="http://www.weather2umbrella.com/">
        www.weather2umbrella.com
      </a>
    </p>
    <p className="update_policy">This policy was updated on 01 October 2024</p>

    <p className="label_policy mt-4 mb-4">Online privacy statement</p>
    <p className="text_policy_stat">
      W2U and its subsidiaries respect your privacy and are committed to protecting it. W2U provides this Privacy Statement to inform you of our Privacy Policy and practices and of the choices you can make about the way your information is collected online and how that information is used. We've structured our websites so that, in general, you can visit W2U on the Web without identifying yourself or revealing any personal information. We make this notice readily available on our home page and at the bottom of every W2U Web page.
    </p>
    <p className="label_policy mt-2">1. Sites covered by this Privacy Statement</p>
    <p className="text_policy">
      This Privacy Statement applies to all W2U.com-owned websites and domains, and our wholly owned subsidiaries ("W2U websites.")
    </p>
    <p className="text_policy">
      Links to non-W2U websites <br />
      The W2U websites may provide links to third-party websites for your convenience and information. If you access those links, you will leave the W2U website. W2U does not control those sites or their privacy practices, which may differ from W2U's. We do not endorse or make any representations about third-party websites. The personal data you choose to give to unrelated third parties is not covered by the W2U Privacy Statement. We encourage you to review the privacy policy of any company before submitting your personal information. Some third-party companies may choose to share their personal data with W2U; that sharing is governed by that third-party company's privacy policy.
    </p>

    <p className="label_policy mt-2">2. Personal Information We Collect Online</p>
    <p className="text_policy">
      This Privacy Statement covers personal information, non-personal data collection and aggregate reporting.
      Personal information is information that is associated with your name or personal identity. W2U uses personal information to understand better your needs and interests and to provide you with better service. Once you choose to provide us with personal information, you can be assured it will be used only to support your customer relationship with W2U. We take seriously the trust you place in us. W2U will not sell, rent or lease your personal information to others.
    </p>
    <p className="text_policy">
      On some W2U websites, you can order products or services, request information, subscribe to marketing or support materials, register yourself or your W2U products, or apply for a job at W2U. The types of personal information you provide to us on these pages may include name, address, phone number, e-mail address, user IDs and passwords, billing and transaction information, credit card information, contact preferences, educational and employment background, and job interest data.
    </p>
    <p className="text_policy">
      To personalize our websites, services or communications and improve our products and services, we may also ask you to provide us with information regarding your personal or professional interests, demographics, and experiences with our products or services. Providing this additional information is optional.
    </p>
    <p className="text_policy">
      Non-personal information <br />
      is data about usage and service operation that is not associated with a specific personal identity. W2U collects and analyzes non-personal information to evaluate how visitors use the W2U websites.
    </p>
    <p className="text_policy">
      Non-personal data we collect may include the pages visited on the W2U websites, unique URLs1 visited within W2U.com, browser type and IP2 address. Most non-personal data is collected via cookies or other analysis technologies. W2U.com Web pages use cookies, Web beacons and other technologies for data analysis and personalization services. W2U also places ads on other websites that may use cookies.
    </p>

    <p className="label_policy mt-2">3. Children's privacy</p>
    <p className="text_policy">
      W2U is committed to protecting the privacy needs of children and we encourage parents and guardians to take an active role in their children's online activities and interests. W2U does not knowingly collect information from children under the age of 13 and W2U does not target its websites to children under 13.
    </p>

    <p className="label_policy mt-2">4. How we use your information</p>
    <p className="text_policy">
      W2U uses your personal information to provide you with services and to help us better understand your needs and interests. Specifically, we use your information to help you complete a transaction or order, to communicate with you, to provide service and support, to update you on services and benefits, to personalize promotional offers and to personalize some W2U websites. Occasionally we may also use your information to contact you for market research regarding W2U products or services. We will give you the opportunity to choose your privacy preferences regarding such communications. Credit card information is used only for payment processing and fraud prevention. Credit card information and sensitive personal data are not used for any other purpose by our financial services providers or W2U and will not be kept longer than necessary for providing the services, unless you ask us to retain your credit card information for future purchases.
    </p>
    <p className="text_policy">
      In order to offer you a more consistent and personalized experience in your interactions with W2U, information collected through one W2U service may be combined with information obtained through other W2U services.
    </p>
    <p className="text_policy">
      W2U is a global organization, with legal entities, business processes, management structures, and technical systems that cross borders. Our privacy practices are designed to provide protection for your personal information, all over the world. We may share your personal information within W2U and transfer it to countries in the world where we do business. Some countries may provide less legal protection for your information. In such countries W2U will still handle information in the manner we describe here.
    </p>
	  <p>Non-personal data is aggregated for reporting about W2U website usability, performance and effectiveness. It is used to improve the customer experience, usability and site content.</p>

    <p className="label_policy mt-2">5. Who we share your information with</p>
    <p className="text_policy">
      W2U will not sell, rent or lease your personal information to others. W2U will not share your personal information with third parties except in responding to your requests for products or services. Your permission will be requested when you submit your information. W2U shares customer information across companies working on our behalf and W2U-owned business entities, but only as described above in "How we use your information."
    </p>
	  <p>W2U contracts with third-party service providers and suppliers to deliver complete products, services and customer solutions described in "How we use your information." Suppliers and service providers are required to keep confidential the information received on behalf of W2U and may not use it for any purpose other than to carry out the services they are performing for W2U. These service providers may change or we may contract with additional service providers to better accommodate our customers.</p>
	  <p>W2U will not share personal information with any other third parties without your permission, unless to: (i) respond to duly authorized information requests of governmental authorities; (ii) comply with any law, regulation, subpoena, or court order, (iii) help prevent fraud or to enforce or protect the rights and properties of W2U or its subsidiaries; or (iv) to protect the personal safety of W2U employees and third parties on W2U property.</p>
	  <p>Circumstances may arise where, whether for strategic or other business reasons, W2U decides to sell, buy, merge or otherwise reorganize businesses in some countries. Such a transaction may involve the disclosure of personal information to prospective or actual purchasers, or receiving it from sellers. It is W2U’s practice to seek appropriate protection for information in these types of transactions.</p>

    <p className="label_policy mt-2">6. Your choices</p>
    <p className="text_policy">
      W2U gives you the choice of receiving a variety of information that complements our products and services. You can subscribe to receive certain product- and service-specific information and W2U-wide marketing communications. W2U-wide communications may include new product information, special offers, or an invitation to participate in market research. We give you a choice regarding delivery of W2U-wide communications by postal mail, e-mail and telephone. You can make or change your choices at the data collection point or through W2U Passport (W2U's proprietary online customer registration tool, available only in Europe). We will make every effort to honor your preferences.
    </p>
	  <p className="label_policy mt-2">7. Your information and third-party companies</p>
	  <p>Certain W2U services are linked with those from unrelated third-party companies, some which offer you the option to share with both W2U and the third party personal data you provide. Examples include the ability to register software products from multiple vendors from a single W2U Web page, to request communications from W2U marketing or solution partners, or to enable order completion through a reseller. We will not share your personal information with those third-party companies unless you make that choice.</p>
	  <p className="label_policy mt-2">8. Access to and accuracy of your information</p>
	  <p>W2U strives to keep your personal information accurate. We have implemented technology, management processes and policies to maintain customer data accuracy. We will provide you with access to your information, including making reasonable effort to provide you with online access and the opportunity to change your information. To protect your privacy and security, we will also take reasonable steps to verify your identity, such as a password and user ID, before granting access to your data. Certain areas of W2U's websites may limit access to specific individuals through the use of passwords and other personal identifiers.</p>
	  <p>The most effective way to view and change your personal information is to return to the Web page where you originally submitted your data and follow the instructions on that Web page or use W2U Passport.</p>
	  <p className="label_policy mt-2">9. Keeping your information secure</p>
	  <p>W2U is committed to protecting the information you provide us. To prevent unauthorized access or disclosure, to maintain data accuracy, and to ensure the appropriate use of the information, W2U has in place appropriate physical and managerial procedures to safeguard the information we collect.</p>
	  <p>We use Secure Sockets Layer (SSL) encryption when collecting or transferring sensitive data such as credit card information. SSL encryption is designed to make the information unreadable by anyone but us. This security measure is working when you see either the symbol of an unbroken key or closed lock (depending on your browser) on the bottom of your browser window.</p>
	  <p>Credit card numbers are used only for processing payment and are not used for other purposes. As part of real-time payment processing, W2U subscribes to a fraud management service. This service gives you and W2U an extra level of security to guard against credit card fraud to protect your financial data.</p>
	  <p className="label_policy mt-2">10. Changes to this Statement</p>
	  <p>If there are updates to the terms of W2U's Online Privacy Statement, we will post those changes and update the revision date in this document, so you will always know what information we collect online, how we use it, and what choices you have. For material changes to this Statement, W2U will provide notification to affected customers.</p>
	  <p className="label_policy mt-2">11. Give Us Your Feedback</p>
	  <p>Our goal is to protect your privacy. If you have comments or questions about our privacy policy, please send them to the W2U Privacy Office.</p>
  </div>
);

const aboutContent = (
  <div>
    <h4 className="font-semibold text-lg mb-6">About W2U</h4>
	<p>Weather2Umbrella LTD</p>
	<p>20-22 Wenlock Road</p>
	<p>N1 7GU</p>
	<p>London</p>
	<p>England</p>
	<p>United Kingdom</p>
	<p>Company registration number: 7802934</p>
	<p className="mt-6">From the outset, Weather2Umbrella Ltd has been driven by the vision to bridge the gap
between individual consumers and businesses through the innovative use of meteorological
data, operating with a B2C2B model. The company has aimed to transform how weather
forecasts are perceived </p>
	<p>The mission of W2U has been clear: to leverage advanced technology and a profound
understanding of meteorology to provide accurate, timely, and tailored weather forecasts
directly to consumers, while also enabling businesses to optimise their operations and mitigate
weather-related risks. At the heart of this mission is the ambition to create an ecosystem in
which the value of meteorological data is fully realised, enriching the quality of life for individuals
and enhancing the efficiency of business processes.</p>
	<p>Established in London, W2U has committed itself to the advancement of weather forecasting,
focusing on the development of high-quality, high-resolution numerical weather prediction
models for Europe and the USA. By offering weather data through websites and apps, as well
as specialised services for the energy sector, maritime industry, and agricultural planning, W2U
continually updates its technology and services to meet the ever-changing needs of its clients.</p>
	<p>In its continuous efforts, Weather2Umbrella aims to provide reliable weather information for
everyday needs while supporting businesses by offering access to quality meteorological data
and analysis. This approach enables W2U to integrate the needs of individuals and businesses,
contributing to the evolving understanding and use of weather data globally.</p>
	<p className="mt-4">Welcome and enjoy using the W2U website!</p>
  </div>
);



export default Footer;

