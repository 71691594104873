import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-velocity/dist/leaflet-velocity.css';
import 'leaflet-velocity-ts/dist/leaflet-velocity.js';
import './WeatherMap.css';
import { useMediaQuery } from 'react-responsive';
import { fromZonedTime } from 'date-fns-tz';


const WeatherMap = ({ cityID, name, countrycode, timezone, latitude, longitude, currentDate, currentHour, onFullScreenChange, userLang }) => {
  const [map, setMap] = useState(null);
  const formattedDate = currentDate.replace(/-/g, '');
  const formattedHour = parseInt(currentHour, 10);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isFullScreen, setIsFullScreen] = useState(isMobile);

  const [utcHour, setUtcHour] = useState(null);
  const [utcDate, setUtcDate] = useState(null);
  const [dataType, setDataType] = useState('png'); 
  const [weatherType, setWeatherType] = useState('tmp850mb'); 
  

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [localHour, setLocalHour] = useState(currentHour);
  const [localDate, setLocalDate] = useState(currentDate);

  
  const [isLeftArrowActive, setIsLeftArrowActive] = useState(false);
  const [isRightArrowActive, setIsRightArrowActive] = useState(false);
  
  const mapRef = useRef(null);
  const weatherLayerRef = useRef(null);
  const centeredDivRef = useRef(null);
  const rightDivRef = useRef(null);
  const [isFirstClick, setIsFirstClick] = useState(true);
  
  
  const updateDataType = (type) => {
      if (type === 'wind10m' || type === 'wind100m') {
        setDataType('json');
      } else {
        setDataType('png');
      }
  };
  
  const loadWindData = (date, hour, weatherType) => {
    if (!mapRef.current) return;

    const mapInstance = mapRef.current;
    const bounds = mapInstance.getBounds();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    
    const europeBounds = { lat0: 25, lat1: 71.5, lon0: -20, lon1: 46.4 };
    const greeceBounds = { lat0: 33, lat1: 47, lon0: 9, lon1: 32 };
    const americaBounds = { lat0: 16, lat1: 57.5, lon0: -126.4, lon1: -60 };

    let url;
    let region;

    if (
      northEast.lat <= europeBounds.lat1 &&
      southWest.lat >= europeBounds.lat0 &&
      northEast.lng <= europeBounds.lon1 &&
      southWest.lng >= europeBounds.lon0
    ) {
      region = "europe";
      url = `https://cdn.weather2umbrella.com/web/json/nmmb/europe/${date}/${hour}/${weatherType}/${weatherType}_V2.json`;
    } else if (
      northEast.lat <= greeceBounds.lat1 &&
      southWest.lat >= greeceBounds.lat0 &&
      northEast.lng <= greeceBounds.lon1 &&
      southWest.lng >= greeceBounds.lon0
    ) {
      region = "greece";
      url = `https://cdn.weather2umbrella.com/web/json/nmmb/greece/${date}/${hour}/${weatherType}/${weatherType}_V2.json`;
    } else if (
      northEast.lat <= americaBounds.lat1 &&
      southWest.lat >= americaBounds.lat0 &&
      northEast.lng <= americaBounds.lon1 &&
      southWest.lng >= americaBounds.lon0
    ) {
      region = "america";
      url = `https://cdn.weather2umbrella.com/web/json/nmmb/america/${date}/${hour}/${weatherType}/${weatherType}_V2.json`;
    } else {
      region = "world";
      url = `https://cdn.weather2umbrella.com/web/json/gfs/world/${date}/${hour}/${weatherType}/${weatherType}_V2.json`;
    }

    
  if (weatherLayerRef.current) {
    if (typeof weatherLayerRef.current.stop === "function") {
      weatherLayerRef.current.stop(); 
    }
    mapInstance.removeLayer(weatherLayerRef.current);
    weatherLayerRef.current = null; 
  }

   
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
	      if (weatherLayerRef.current) {
	       
	        mapRef.current.removeLayer(weatherLayerRef.current);
	      }
        
        const velocityOptions = {
          displayValues: true,
          displayOptions: {
            showCardinal: true,
            velocityType: "Global Wind",
            position: "bottomleft",
            emptyString: "No velocity data",
            directionString: "Direction",
            speedString: "Speed",
            speedUnit: "m/s",
            angleConvention: "bearingCW",
          },
          data: data,
          particleAge: 74,
          particleMultiplier: 0.0033,
          particlelineWidth: 1,
          frameRate: 15,
          velocityScale: 0.015,
          minVelocity: weatherType === "wind100m" ? 5 : 0,
          maxVelocity: weatherType === "wind100m" ? 25 : 15,
        };

        
        const velocityLayer = L.velocityLayer(velocityOptions);
        velocityLayer.addTo(mapInstance);
        weatherLayerRef.current = velocityLayer;
      })
      .catch((error) => console.error("Error loading wind data:", error));
  };

  
  const changeWeatherLayer = (dataType, date, hour, weatherType) => {
    if (weatherLayerRef.current) {
      
      mapRef.current.removeLayer(weatherLayerRef.current);
    }

   
    const layerUrl = `https://cdn.weather2umbrella.com/web/${dataType}/gfs/world/${date}/${hour}/${weatherType}/{z}/{x}/{y}.png`;
    let newLayer = L.tileLayer(layerUrl, {
      minZoom: 2,
      maxZoom: 8,
      tms: true,
      opacity: 0, 
    }).addTo(mapRef.current);

    
    weatherLayerRef.current = newLayer;

    
    let maxOpacity = 0.9;
    if (weatherType === "tmp2m" || weatherType === "tmp850mb") {
      maxOpacity = 0.65;
    }

    
    let opacity = 0;
    const interval = setInterval(() => {
      opacity += 0.1;
      newLayer.setOpacity(opacity);

     
      if (opacity >= maxOpacity) {
        clearInterval(interval);
      }
    }, 30);
  };

  
  
  const convertToUTC = (convDate, convHour) => {
    const date = new Date(convDate);
    const dateTimeString = `${date.toISOString().split('T')[0]}T${String(convHour).padStart(2, '0')}:00:00`;
    const utcDateTime = fromZonedTime(dateTimeString, timezone);
    return {
      utcDate: utcDateTime.toISOString().split('T')[0].replace(/-/g, ''),
      utcHour: utcDateTime.getUTCHours(),
    };
  };
  

 
  const handleDateClick = (offset) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + offset);

    const { utcDate, utcHour: newUtcHour } = convertToUTC(newDate, localHour);

    
    setLocalDate(newDate.toISOString().split('T')[0]);
    setSelectedDate(newDate.toISOString().split('T')[0]);

   
    setUtcDate(utcDate);
    setUtcHour(newUtcHour);

    if (weatherType === 'wind10m' || weatherType === 'wind100m') {
      loadWindData(utcDate, newUtcHour, weatherType);
    } else {
      changeWeatherLayer(dataType, utcDate, newUtcHour, weatherType);
    }
  };


  
  const handleWeatherTypeClick = (newWeatherType) => {
    setWeatherType(newWeatherType || 'tmp850mb');
    updateDataType(newWeatherType);

    
    if (newWeatherType === 'wind10m' || newWeatherType === 'wind100m') {
      loadWindData(utcDate, utcHour, newWeatherType);
    } else {
      changeWeatherLayer(dataType, utcDate, utcHour, newWeatherType);
    }
  };


 
  useEffect(() => {
    
    if (!currentDate || currentHour === undefined || currentHour === null) {
      return; 
    }

   
    const date = new Date(currentDate);
    const dateTimeString = `${date.toISOString().split('T')[0]}T${String(currentHour).padStart(2, '0')}:00:00`;

    
    const utcDateTime = fromZonedTime(dateTimeString, timezone);
    
   
    const utcHour = utcDateTime.getUTCHours();
    const utcDateString = utcDateTime.toISOString().split('T')[0];
	const utcDate = utcDateString.replace(/-/g, '');

    
    setUtcHour(utcHour);
    setUtcDate(utcDate);
	setSelectedDate(currentDate);
	setLocalDate(currentDate);
	setLocalHour(currentHour);

  }, [currentDate, currentHour, timezone]);
    
  useEffect(() => {
   
    const mapInstance = L.map('map', {
      zoomControl: false,
    }).fitWorld();

    
    setMap(mapInstance);
	mapRef.current = mapInstance;

   
    const tiles = L.tileLayer('https://cdn.weather2umbrella.com/web/world_tiles/world_grey/{z}/{x}/{y}.png', {
      minZoom: 2,
      maxZoom: 8,
      tms: true,
      opacity: 0.9,
    }).addTo(mapInstance);
	
	let worldGeoJsonLayer; 

	const addWorldGeoJsonLayer = () => {
	  const geoJsonUrl = 'https://cdn.weather2umbrella.com/web/world/world.json';

	  fetch(geoJsonUrl)
	    .then((response) => response.json())
	    .then((data) => {
	      
	      if (worldGeoJsonLayer) {
	        mapInstance.removeLayer(worldGeoJsonLayer);
	      }

	      
	      worldGeoJsonLayer = L.geoJSON(data, {
	        style: function (feature) {
	          return {
	            color: '#ffffff',
	            weight: 1,
	            opacity: 0.35,
	            fillColor: '#ff7800',
	            fillOpacity: 0,
	          };
	        },
	      }).addTo(mapInstance);
	    })
	    .catch((err) => console.error('Error loading GeoJSON:', err));
	};

	
	mapInstance.on('zoomend', () => {
	  addWorldGeoJsonLayer();
	});
	
    const zoomLevel = 5;
    mapInstance.setView([latitude || 51.5, longitude || -0.116667], zoomLevel);
	
	const capitalizeName = (name) => {
	  return name
	    .split(' ') 
	    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) 
	    .join(' '); 
	};
	const formatDate = (dateString) => {
	  const year = dateString.substring(0, 4);
	  const month = dateString.substring(4, 6);
	  const day = dateString.substring(6, 8);
	  return `${day}.${month}.${year}`;
	};
    
    const locationInfoControl = L.control({ position: 'topleft' });

    locationInfoControl.onAdd = function () {
      const div = L.DomUtil.create('div', 'leaflet-bar location-info');
      div.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
      div.style.color = 'white';
      div.style.padding = '5px';
      div.style.fontSize = '14px';
      div.style.cursor = 'pointer';
      div.style.textAlign = 'center';
	  div.style.flexDirection = 'column'; 
	  div.style.alignItems = 'center'; 
	  div.style.justifyContent = 'center'; 
	  div.style.display = 'flex';
	  div.style.border = 'none';

	  const formattedDisplayDate = formatDate(formattedDate);
	  const formattedName = capitalizeName(name);
      const infoText = `<span style="white-space: nowrap;">
        ${formattedName}, ${countrycode}: ${latitude}, ${longitude}<br/>
        ${formattedDisplayDate} <span style="font-weight: 600;">${formattedHour}h</span> - ${timezone}</span>
      `;
      div.innerHTML = infoText;

      div.onclick = () => {
        mapInstance.setView([latitude, longitude], 6); 
      };

      return div;
    };

    locationInfoControl.addTo(mapInstance);
	mapInstance.setView([latitude, longitude], 6);

  
	const mapModelControl = L.control({ position: 'topleft' });

	mapModelControl.onAdd = function () {
	 
	  this._div = L.DomUtil.create('div', 'map-model');
	  this._div.style.backgroundColor = 'rgba(0, 0, 255, 0.3)';
	  this._div.style.color = 'white';
	  this._div.style.padding = '5px 10px';
	  this._div.style.borderRadius = '0.5rem';

	 
	  this.update();

	  return this._div;
	};
	mapModelControl.update = function () {
	  const zoom = mapInstance.getZoom();
	  let modelText = zoom > 5 ? 'NMMB' : zoom === 5 ? 'NMMB-GFS' : 'GFS';
	  this._div.innerHTML = modelText; 
	};
	
	mapModelControl.addTo(mapInstance);
	
	mapInstance.on('zoomend', () => mapModelControl.update());
	


    
	const loadGeoJsonByZoom = (zoomLevel) => {
	  const fileName = `${zoomLevel}.json`;
	  const url = `https://cdn.weather2umbrella.com/web/cities/${fileName}`;

	  fetch(url)
	    .then((response) => response.json())
	    .then((data) => {
	      if (window.geoJsonLayer) {
	        mapInstance.removeLayer(window.geoJsonLayer);
	      }

	      window.geoJsonLayer = L.geoJSON(data, {
	        pointToLayer: (feature, latlng) => {
	          const markerHtml = `
	            <div class="custom-marker-wrapper">
	              <div class="small-dot"></div>
	              <div class="city-name">${feature.properties.city}</div>
	            </div>`;
	          return L.marker(latlng, {
	            icon: L.divIcon({
	              className: '', // Set to empty to avoid default styles
	              html: markerHtml,
	            }),
	          });
	        },
	      }).addTo(mapInstance);
	    })
	    .catch((error) => console.error('Error loading GeoJSON data:', error));
	};
    
	loadGeoJsonByZoom(zoomLevel);

    mapInstance.on('zoomend', () => {
      const zoomLevel = mapInstance.getZoom();
      loadGeoJsonByZoom(zoomLevel);
    });
	
	
	if (!isMobile) {
	  const fullScreenControl = L.control({ position: 'topright' });
	
  
      fullScreenControl.onAdd = function () {
        const div = L.DomUtil.create('div', 'leaflet-bar fullscreen-control');
        div.innerHTML = isFullScreen ? 'Exit Full Screen' : 'Full Screen';
        div.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        div.style.color = 'white';
        div.style.padding = '5px';
        div.style.cursor = 'pointer';
        div.style.textAlign = 'center';

     
		div.onclick = () => {
			setIsFullScreen((prev) => {
			    const newState = !prev;
			    updateMapSize(newState); 
			    return newState;
			  });
		        };

        return div;
      };
   
      fullScreenControl.addTo(mapInstance);
    }
	
   
    const updateMapSize = (fullScreenState) => {

      if (isFullScreen || isMobile) {
      
        mapInstance.invalidateSize();
        mapInstance.getContainer().style.height = '100vh';
        mapInstance.getContainer().style.width = '100vw';
        mapInstance.getContainer().style.position = 'fixed';
        mapInstance.getContainer().style.top = '0';
        mapInstance.getContainer().style.left = '0';
      } else {
       
        mapInstance.invalidateSize();
        mapInstance.getContainer().style.height = '227px';
        mapInstance.getContainer().style.width = '493px';
        mapInstance.getContainer().style.position = 'relative';
      }
     
      setTimeout(() => {
        mapInstance.invalidateSize();
      }, 0);
    };
	
   
    updateMapSize(isFullScreen);

    return () => {
      mapInstance.remove();
    };
  }, [latitude, longitude, formattedDate, formattedHour, isFullScreen, isMobile, countrycode, name, timezone]);

  useEffect(() => {
    if (utcDate && utcHour) {
      
      if (weatherType === 'wind10m' || weatherType === 'wind100m') {
        loadWindData(utcDate, utcHour, weatherType);
      } else {
      
        changeWeatherLayer(dataType, utcDate, utcHour, weatherType);
      }
    }
  }, [utcDate, utcHour, weatherType, dataType]);


  
  useEffect(() => {
    const handleLocationChange = () => {
      const lat = parseFloat(localStorage.getItem('latitude')) || 51.5;
      const lon = parseFloat(localStorage.getItem('longitude')) || -0.116667;

      if (mapRef.current) {
        mapRef.current.setView([lat, lon], 5);
      }
    };

    window.addEventListener('storage', handleLocationChange);

    handleLocationChange();

    return () => {
      window.removeEventListener('storage', handleLocationChange);
    };
  }, []);
  
  useEffect(() => {
   
    onFullScreenChange(isFullScreen);
  }, [isFullScreen, onFullScreenChange]);
  


  const handleSliderChange = (e) => {
    const newHour = parseInt(e.target.value, 10);

    if (!utcDate || utcHour === null) {
      return; 
    }

 
    setLocalHour(String(newHour).padStart(2, '0'));

    
    const { utcDate: newUtcDate, utcHour: newUtcHour } = convertToUTC(localDate, newHour);

   
    setUtcDate(newUtcDate);
    setUtcHour(newUtcHour);

  

    
    if (weatherType === 'wind10m' || weatherType === 'wind100m') {
      loadWindData(newUtcDate, newUtcHour, weatherType);
    } else {
      changeWeatherLayer(dataType, newUtcDate, newUtcHour, weatherType);
    }
  };



  
 
  const getGeoReferencedPosition = () => {
    if (map) {
      
      const point = map.latLngToLayerPoint([latitude, longitude]);
      
     
      const offsetX = isMobile ? -140 : -350;
      const offsetY = isMobile ? 100 : 300;

     
      return {
        left: `${point.x + offsetX}px`,
        top: `${point.y + offsetY}px`,
      };
    }
    return { left: '0px', top: '0px' };
  };
  
  
  useEffect(() => {
    if (isFullScreen && centeredDivRef.current) {
      
      const mapWidth = mapRef.current.getSize().x;
	  const offset = isMobile ? 20 : -60;

     
	  centeredDivRef.current.style.marginLeft = `${offset}px`;
      centeredDivRef.current.style.left = '0';
      centeredDivRef.current.style.top = '76%';
      centeredDivRef.current.style.transform = 'translateY(-50%)';
    }
  }, [isFullScreen, isMobile]);
  
  useEffect(() => {
    if (isFullScreen && rightDivRef.current) {
      
      const mapWidth = mapRef.current.getSize().x;
	  const offset = isMobile ? 110 : 0;
	  const margin = isMobile ? 53 : 46;

      
	  rightDivRef.current.style.marginLeft = `${offset}px`;
      rightDivRef.current.style.left = `${margin}%`;
      rightDivRef.current.style.top = '40%';
      rightDivRef.current.style.transform = 'translateY(-50%)';
    }
  }, [isFullScreen, isMobile]);
  
  useEffect(() => {
    
    if (!selectedDate) {
      setSelectedDate(currentDate);
    }
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);
  
  useEffect(() => {
    setLocalHour(parseInt(currentHour, 10));
	setIsFirstClick(true);
  }, [currentHour]);
  
// 
  useEffect(() => {
    setLocalDate(currentDate);
  }, [currentDate]);
//
    
  const getDayName = (date, lang) => {
    const days = {
      en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      rs: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
      hr: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
      el: ['Κυρ', 'Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
	  al: ['Die', 'Hën', 'Mar', 'Mër', 'Enj', 'Pre', 'Sht'],
	  mk: ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Саб']
    };

    const dayIndex = date.getDay();
    return days[lang][dayIndex];
  };
  
  useEffect(() => {
    
    handleWeatherTypeClick(weatherType);
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen, weatherType]);
  
  useEffect(() => {
    const { utcDate, utcHour } = convertToUTC(localDate, localHour);
    setUtcDate(utcDate);
    setUtcHour(utcHour);
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDate, localHour, timezone]);
  
  

  
  
  return (
      <div className={`relative ${isFullScreen || isMobile ? 'h-screen w-screen' : 'h-[227px] w-[493px]'}`}>
        <div id="map" className="w-full h-full" />
	  

	  {isFullScreen && (
	    <div ref={centeredDivRef} className="absolute flex flex-col items-center">
	      <div className="hour-slider flex flex-col items-center relative">
      
	      
	        <div className="absolute -top-6 slider-value whitespace-nowrap text-white">
	          {localHour}h
	        </div>

	        
	        <div className="flex items-center">
	          <button
	            onClick={() => {
	              setLocalHour((prev) => Math.max(0, prev - 1));
	              setIsLeftArrowActive(true);
	              setTimeout(() => setIsLeftArrowActive(false), 200); 
	            }}
				disabled={localHour === 0}
	            style={{
	              backgroundColor: localHour === 0 ? 'rgba(0, 0, 0, 0.2)' : (isLeftArrowActive ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 255, 0.3)'),
				  cursor: localHour === 0 ? 'not-allowed' : 'pointer',
	            }}
	            className="text-white py-1 px-4 rounded-lg mr-2 flex items-center"
	          >
	            <img 
	              src="/images/arrowL.svg" 
	              alt="Left Arrow" 
	              className="w-5 h-5"
	            />
	          </button>

	          <input 
	            type="range" 
	            min="0" 
	            max="23" 
	            value={localHour} 
	            onChange={(e) => setLocalHour(parseInt(e.target.value))}
				//onChange={handleSliderChange}
	            className="w-[200px]"
				disabled={weatherType === 'wind10m' || weatherType === 'wind100m'}
	          />

	          <button
	            onClick={() => {
				  if (isFirstClick) {
					     
					    setLocalHour((prev) => Math.max(0, prev - 1)); 
					    setTimeout(() => {
					      setLocalHour((prev) => Math.min(23, prev + 2)); 
					      setIsFirstClick(false); 
					    }, 50); 
				  } else {
					      
					      setLocalHour((prev) => Math.min(23, prev + 1));
				  }
	              setIsRightArrowActive(true);
	              setTimeout(() => setIsRightArrowActive(false), 200); 
	            }}
				disabled={localHour === 23}
	            style={{
	              backgroundColor: localHour === 23 ? 'rgba(0, 0, 0, 0.2)' : (isRightArrowActive ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 255, 0.3)'),
				  cursor: localHour === 23 ? 'not-allowed' : 'pointer',
	            }}
	            className="text-white py-1 px-4 rounded-lg ml-2 flex items-center"
	          >
	            <img 
	              src="/images/arrowR.svg" 
	              alt="Right Arrow" 
	              className="w-5 h-5"
	            />
	          </button>
	        </div>
	      </div>

	      {/* <div className="absolute top-20">
	        <div className="utc-date text-white whitespace-nowrap">
	          UTC: {utcDate} {utcHour}h
	        </div>
	        <div className="utc-date text-white whitespace-nowrap">
	          Your local: {localDate} {localHour}h
	        </div>
	      </div> */}

	      {/* Dugmad za promenu dana */}
	      <div className="flex space-x-4 mt-4">
	        {['-1', '0', '+1', '+2'].map((offset, index) => {
	          const dateOffset = parseInt(offset, 10);
	          if (isNaN(dateOffset)) return null;

	          const newDate = new Date(currentDate);
	          newDate.setDate(newDate.getDate() + dateOffset);
	          const dayName = getDayName(newDate, userLang);

	          const isActive = newDate.toISOString().split('T')[0] === selectedDate;

	          return (
	            <button
	              key={index}
	              style={{
	                backgroundColor: isActive ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 255, 0.3)',
	              }}
	              className="text-white py-1 px-4 rounded-lg"
	              onClick={() => {
	                if (localHour === 23 && (offset === '0' || offset === '+1' || offset === '+2')) {
	                  
	                  setLocalHour(0);
	                  setLocalDate(newDate.toISOString().split('T')[0]);
	                } else if (localHour === 0 && (offset === '-1' || offset === '0' || offset === '+1')) {
	                  
	                  setLocalHour(23);
	                  setLocalDate(newDate.toISOString().split('T')[0]);
	                } else {
	                 
	                  setLocalDate(newDate.toISOString().split('T')[0]);
	                }
	                handleDateClick(dateOffset);
	              }}
	            >
	              {dayName}
	            </button>
	          );
	        })}
	      </div>
	    </div>
	  )}

	  {isFullScreen && (
	    <div ref={rightDivRef} className="absolute flex flex-col">
	      {['cld', 'tmp2m', 'tmp850mb', 'pcp', 'rain', 'snow', 'wind10m', 'wind100m'].map((type, index) => (
	        <button
	          key={index}
	          style={{
	            backgroundColor: type === weatherType ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 255, 0.3)',
	          }}
	          className="text-white py-2 px-2 rounded-lg flex items-center space-x-2 mb-2"
	          onClick={() => handleWeatherTypeClick(type)}
	        >
	          <img
	            src={`/images/${type}.svg`}
	            alt={type}
	            className="w-7 h-7"
	          />
	          
	        </button>
	      ))}
	    </div>
	  )}

      </div>
    );
};

export default WeatherMap;




