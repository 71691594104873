import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);

const WeatherChart = ({ hourlyData, activeDate, themeMod, getTemperatureColor, getAlert, currentDate, currentHour, isFullScreen, convertTemperature, tempUnits }) => {
  
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dateOnly = activeDate.split(' ')[0];
  const filteredData = Object.entries(hourlyData)
    .filter(([key]) => key.startsWith(dateOnly))
    .map(([key, forecast]) => ({
      hour: key.split(' ')[1].slice(0, 2), 
	  tmp: convertTemperature(forecast.tmp, tempUnits).slice(0, -2), 
      pcp: forecast.pcp || 0, 
      icon: forecast.icon, 
	  tmpColor: getTemperatureColor(forecast.tmp, themeMod === 'dark'),
	  alertIcon: getAlert(forecast),
      currentHour: currentHour,
	  currentDate: currentDate,
    }));

  const hours = filteredData.map(data => data.hour);
  const temperatures = filteredData.map(data => data.tmp);
  const precipitation = filteredData.map(data => data.pcp);
  const icons = filteredData.map(data => data.icon);
  const alertIcons = filteredData.map(data => data.alertIcon);

  const maxPrecipitation = Math.max(...precipitation);
  
  const temperatureColors = filteredData.map(data => data.tmpColor);
  
  const formattedCurrentDate = `${currentDate} 00:00:00`;
  
  const highlightedPointBackgroundColors = filteredData.map(data => {
    return (formattedCurrentDate === activeDate && data.hour === currentHour)
      ? '#FF4500' 
      : data.tmpColor;
  });

  const highlightedPointBorderColors = filteredData.map(data => {
    return (formattedCurrentDate === activeDate && data.hour === currentHour)
      ? '#FF4500' 
      : '#2196F3'; 
  });
  
  const highlightIndex = filteredData.findIndex(data => formattedCurrentDate === activeDate && data.hour === currentHour);

  const highlightValue = Math.max(...temperatures) * 1.2 || 10;


  const data = {
    labels: hours,
      
      ...(highlightIndex !== -1 ? [{
        label: '',
        type: 'bar',
        data: highlightValue,
        backgroundColor: 'rgba(255, 69, 0, 0.2)', 
        borderWidth: 0, 
        yAxisID: 'y',
        barThickness: 20, 
		datalabels: {
		  display: false, 
		},
      }] : []),
    datasets: [
      {
        label: '',
        type: 'bar',
        data: precipitation,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
		barThickness: 10,
        yAxisID: 'y',
		datalabels: { 
		  display: false,
		},
      },
      {
        label: '',
        type: 'line',
        data: temperatures,
        backgroundColor: '#2196F3',
        borderColor: '#2196F3',
        borderWidth: 1,
        fill: false,
        yAxisID: 'y1',
        pointRadius: 3, 
        pointHoverRadius: 3, 
        pointBackgroundColor: highlightedPointBackgroundColors, 
        pointBorderColor: highlightedPointBorderColors, 
        //tension: 0.3, 
      },
    ],
  };
  
  Chart.register(ChartDataLabels);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
		  color: themeMod === 'dark' ? '#A0A0A0' : '#B3B3B3',
          callback: (val, index) => hours[index], 
        },
		border: {
		  color: themeMod === 'dark' ? '#A0A0A0' : '#B3B3B3',  
		},
      },
      y: {
        type: 'linear',
        position: 'right',
        max: maxPrecipitation > 1 ? maxPrecipitation * 5 : maxPrecipitation * 10,
        grid: { drawOnChartArea: false },
        ticks: {
  		  color: themeMod === 'dark' ? '#A0A0A0' : '#B3B3B3',
        },
		border: {
		  color: themeMod === 'dark' ? '#A0A0A0' : '#B3B3B3', 
		},
      },
      y1: {
		beginAtZero: true,
        type: 'linear',
        position: 'left',
		max: 
		    Math.max(...temperatures) < 0 
		      ? Math.max(...temperatures) * -0.2 
		      : Math.max(...temperatures) === 0 
		        ? Math.max(Math.max(...temperatures), 2.1) * 1.8 
		        : Math.max(...temperatures) * 1.8,
        grid: { drawOnChartArea: false },
        ticks: {
    	  color: themeMod === 'dark' ? '#A0A0A0' : '#B3B3B3',
        },
		border: {
		  color: themeMod === 'dark' ? '#A0A0A0' : '#B3B3B3', 
		},
      },
    },
    plugins: {
      legend: {
		display: false, 
	  },
	  datalabels: {
	        display: true, 
	        align: 'top',  
	        color: temperatureColors, 
	        font: {
		      size: 17,
	          //weight: '600',
	        },
	        formatter: (value) => `${value}°`, 
	  },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `${tooltipItem.raw}°`; 
            }
            return `${tooltipItem.raw} mm`;
          },
        },
      },
    },
  };

  return (
<div className={`relative h-[250px] w-[856px] sm:h-[280px] sm:w-[856px] md:scrollbar-hide overflow-x-auto ${isDesktop && isFullScreen ? 'z-[-1]' : ''}`}>
    
      <Line data={data} options={options} />
      <div className="absolute top-2 left-6 right-6 flex justify-between px-2">
        {icons.map((icon, idx) => (
          <img key={idx} src={`/images/${icon}.png`} alt="Weather Icon" className="w-8 h-8" />
        ))}
      </div>
		{/* Alert icon row */}
		<div className="absolute top-10 left-6 right-6 flex justify-between px-2">
		  {alertIcons.map((alertIcon, idx) => (
		    alertIcon ? (
		      <img 
		        key={idx} 
		        src={`/images/${alertIcon}${themeMod === 'dark' ? 'd.png' : '.png'}`} 
		        alt="Alert Icon" 
		        className="w-6 h-6" 
		      />
		    ) : (
		      <div key={idx} style={{ width: '24px' }} /> 
		    )
		  ))}
		</div>
    </div>
  );
};

export default WeatherChart;
